.trade-notice {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f4f4f4;
    z-index: 30;
    padding: 0 14px;

    .modal {
        color: black;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5);
        padding: 2rem 1rem;

        p {
            font-size: clamp(1rem, 3vw, 1.2rem);
            line-height: 28px;
        }

        .close-btn {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-top: 1rem;

            h2 {
                font-size: 1.3rem;
                color: red;
                cursor: pointer;
            }
        }
    }
}

@include for-phone-only {
    .trade-notice {
        padding: 0 24px;
    }
}

@include for-tablet-potrait-up {
    .trade-notice {
        padding: 0 40px;

        .modal {
            width: 60%;
        }
    }
}

@include for-tablet-landscape-up {
    .trade-notice {
        padding: 0 60px;

        .modal {
            width: 60%;
        }
    }
}

@include for-desktop-up {
    .trade-notice {
        padding: 0 40px;

        .modal {
            width: 40%;
        }
    }
}

@include for-big-desktop-up {
    .trade-notice {
        padding: 0 40px;

        .modal {
            width: 30%;
        }
    }
}