$primaryColor: #FCD535;
$darkBg : #181A20;
$darkText: #0E0C28;
$lightText: #EAECEF;
$bluishWhiteColor: #ffffff;
$whiteColor: #fff;
$generalTransition: 0.3s linear all;
$mobileNavbarPadding: 20px 20px;
$mobileBodyPadding: 60px 20px;
$smallestNavbarPadding: 20px 10px;
$smallestBodyPadding: 40px 10px;
$desktopNavbarPadding: 30px;
$desktopBodyPadding: 45px 75px;
$greyBackground: #EFECFB;
$boxShadow: 3px 5px 20px rgba(0, 0, 0, 0.04);