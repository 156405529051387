.guides {
    padding-top: 80px;

    h2 {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 20px;
    }

    h3 {
        font-weight: 500;
        font-size: 20px;
        line-height: 27px;
        margin-bottom: 20px;
        text-transform: uppercase;
    }

    p {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #B7BDC6;
    }

    .block {
        padding: 40px 16px;
    }

    .assets {
        padding: 40px 16px;

        &-container {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            grid-gap: 20px;

            .card {
                display: flex;
                border: 1px solid $primaryColor;
                align-items: center;
                border-radius: 12px;
                flex-direction: column;
                padding: 40px 40px;

                .logo {
                    height: 120px;
                    width: 120px;
                    margin-bottom: 20px;
                    position: relative;
                }
            }
        }
    }

}

@include for-phone-only {
    .guides {
        .assets {


            &-container {
                .card {
                    padding: 40px 20px;

                    .logo {
                        height: 100px;
                        width: 100px;
                    }

                    h3 {
                        padding: 0px 30px;
                        font-size: 1rem;
                    }
                }
            }
        }

    }
}

@include for-tablet-potrait-up {
    .guides {
        h2 {
            font-size: 32px;
            line-height: 40px;
        }

        h3 {
            font-size: 24px;
            line-height: 32px;
        }

        p {
            font-size: 16px;
            line-height: 24px;
        }

        .assets {

            &-container {
                grid-template-columns: repeat(2, 1fr);

                .card {
                    min-height: 450px;
                    position: relative;

                    h3 {
                        padding: 0px 20px;
                        font-size: 20px;
                    }
                }
            }
        }

    }
}

@include for-tablet-landscape-up {}

@include for-desktop-up {
    .guides {

        .block {
            padding: 40px 75px;

            p {
                padding-right: 400px
            }
        }


        .assets {
            padding: $desktopBodyPadding;

            &-container {
                grid-template-columns: repeat(3, 1fr);

                .card {
                    min-height: 400px;
                }
            }
        }
    }
}

@include for-big-desktop-up {}