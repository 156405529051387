.footer {
    padding: $mobileBodyPadding;
    background-color: #181A20;

    .grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;

        .item {
            p {
                font-size: 22px;
                line-height: 1.23;
                font-weight: 700;
            }

            .btns {
                display: flex;
                align-items: center;
                margin-top: 18px;

                button {
                    margin: 0 .5rem;
                    width: 50%;

                    &:first-child {
                        margin-left: 0;
                    }
                }
            }

            h4 {
                font-size: 23px;
                opacity: .48;
                margin: 0 0 12px;
                display: block;
            }

            ul {
                li {
                    margin: 0.5rem 0;

                    &:hover {
                        opacity: .48;
                    }
                }
            }

            &:first-child {
                grid-column: 1/3;
                width: 100%;
            }
        }

    }

    .payment-methods {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 2rem;

        .img {
            width: 120px;
            margin: 0 1rem;
            margin-bottom: 1rem;
        }
    }

    .copyright {
        text-align: center;
        font-size: 1.2rem;
        margin-top: 20px;
    }
}


@include for-phone-only {
    .footer {

        .grid {
            .item {
                p {
                    font-size: 18px;
                }

                .btns {
                    button {
                        font-size: 0.6rem;
                    }
                }

                h4 {
                    font-size: 18px;
                }
            }
        }
    }
}

@include for-tablet-potrait-up {
    .footer {

        .grid {
            .item {
                .btns {
                    button {
                        width: 30%;
                    }
                }
            }
        }
    }
}

@include for-tablet-landscape-up {}

@include for-desktop-up {
    .footer {
        padding: $mobileBodyPadding;

        .grid {
            grid-template-columns: repeat(5, 1fr);

            .item {
                p {
                    font-size: 22px;
                }

                .btns {
                    flex-direction: column;

                    button {
                        margin: .5rem 0;
                        width: 80%;
                    }
                }

                &:first-child {
                    grid-column: 1/1;
                }
            }
        }

    }
}

@include for-big-desktop-up {}