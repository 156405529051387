.page-loader {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
    overflow: hidden;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    .container {
        width: 117px;
        height: 88px;
        position: relative;

        .bitcoin {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #FF9800;
            border-radius: 50%;
            width: 50px;
            height: 50px;
            line-height: 50px;
            font-size: 24px;
            position: absolute;
            bottom: 29px;
            left: 0px;
            transform-origin: center bottom;
            -o-transform-origin: center bottom;
            -ms-transform-origin: center bottom;
            -webkit-transform-origin: center bottom;
            -moz-transform-origin: center bottom;
            animation: bounce 0.69s ease-in-out infinite;
            -o-animation: bounce 0.69s ease-in-out infinite;
            -ms-animation: bounce 0.69s ease-in-out infinite;
            -webkit-animation: bounce 0.69s ease-in-out infinite;
            -moz-animation: bounce 0.69s ease-in-out infinite;

            svg {
                color: #fff;
            }

            @keyframes bounce {
                0% {
                    transform: scale(1, .7);
                }

                20% {
                    transform: scale(.7, 1.2);
                }

                40% {
                    transform: scale(1, 1);
                }

                50% {
                    bottom: 97px;
                }

                46% {
                    transform: scale(1, 1);
                }

                80% {
                    transform: scale(.7, 1.2);
                }

                90% {
                    transform: scale(.7, 1.2);
                }

                100% {
                    transform: scale(1, 0.7);
                }
            }

        }

        .step {
            position: absolute;
            width: 36px;
            height: 36px;
            border-top: 4px solid rgb(0, 0, 0);
            top: 0;
            right: 0;

            &.step-one {
                animation: movingSteps 2.07s linear infinite;
                -o-animation: movingSteps 2.07s linear infinite;
                -ms-animation: movingSteps 2.07s linear infinite;
                -webkit-animation: movingSteps 2.07s linear infinite;
                -moz-animation: movingSteps 2.07s linear infinite;
            }

            &.step-two {
                animation: movingSteps 2.07s linear infinite -0.69s;
                -o-animation: movingSteps 2.07s linear infinite -0.69s;
                -ms-animation: movingSteps 2.07s linear infinite -0.69s;
                -webkit-animation: movingSteps 2.07s linear infinite -0.69s;
                -moz-animation: movingSteps 2.07s linear infinite -0.69s;
            }

            &.step-three {
                animation: movingSteps 2.07s linear infinite -1.38s;
                -o-animation: movingSteps 2.07s linear infinite -1.38s;
                -ms-animation: movingSteps 2.07s linear infinite -1.38s;
                -webkit-animation: movingSteps 2.07s linear infinite -1.38s;
                -moz-animation: movingSteps 2.07s linear infinite -1.38s;
            }


            @keyframes movingSteps {
                0% {
                    opacity: 0;
                    top: 0;
                    right: 0;
                }

                50% {
                    opacity: 1;
                }

                100% {
                    top: 88px;
                    right: 88px;
                    opacity: 0;
                }
            }

        }
    }

}