    .home-header {
        height: 65vh;
        width: 100%;

        .mySwiper4 {
            height: 100%;
            width: 100%;
            background-image: url("../../assets/home-header-bg.jpg");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;

            .swiper-slide {
                width: 100%;
                padding: 3rem 14px 0 14px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .info {
                    width: 60%;

                    h1 {
                        border-left: 5px solid $primaryColor;
                        font-size: clamp(1rem, 5vw, 3.5rem);
                        width: 100%;
                        margin-bottom: 2rem;
                        padding-left: 0.8rem;

                        span {
                            color: $primaryColor;
                        }
                    }

                    p {
                        font-weight: 400;
                        font-size: clamp(0.9rem, 3vw, 1.2rem);
                        margin-bottom: 2rem;
                    }

                    .btns {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;

                        button {
                            margin-right: 1rem;
                            border-radius: 0;
                            min-width: 100px;
                        }
                    }
                }

                &.slide-one {
                    .img {
                        width: 145px;
                        height: 146px;
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img.earth {
                            position: absolute;
                            -webkit-animation: spin 25s infinite linear;
                            animation: spin 25s infinite linear;

                            @keyframes spin {
                                0% {
                                    -webkit-transform: rotate(0deg);
                                    transform: rotate(0deg);
                                }

                                100% {
                                    -webkit-transform: rotate(359deg);
                                    transform: rotate(359deg);
                                }
                            }
                        }

                        img.bitcoin {
                            z-index: 2;
                            position: relative;
                            width: 80px;
                            height: 104px;
                        }
                    }
                }

                &.slide-two {
                    .img {
                        width: 50%;
                        height: 100%;
                        position: relative;
                        overflow: hidden;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .coin-sky {
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            left: 0;
                            top: 0;
                            transform: rotate(180deg);
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            .shower {
                                background-image: url("../../assets/coin-sky.png");
                                background-size: contain;
                                background-position: bottom;
                                background-repeat: no-repeat;
                                height: 50vh;
                                width: 100%;
                                animation: linear infinite shower 15s;

                                @keyframes shower {
                                    from {
                                        transform: none;
                                    }

                                    to {
                                        transform: translateY(-100%);
                                    }
                                }
                            }
                        }

                        .rocket {
                            position: relative;
                            z-index: 2;
                            width: 63px;
                            height: 110px;
                            transition: none 0s ease 0s;
                            animation-name: floating;
                            -webkit-animation-name: floating;
                            animation-duration: 1.5s;
                            -webkit-animation-duration: 1.5s;
                            animation-iteration-count: infinite;
                            -webkit-animation-iteration-count: infinite;

                            @keyframes floating {
                                0% {
                                    transform: translateY(0%);
                                }

                                50% {
                                    transform: translateY(8%);
                                }

                                100% {
                                    transform: translateY(0%);
                                }
                            }
                        }

                        .earth {
                            position: absolute;
                            bottom: -45%;
                            left: -10%;
                            width: 192px;
                            height: 192px;
                            -webkit-animation: spin 25s infinite linear;
                            animation: spin 25s infinite linear;

                            @keyframes spin {
                                0% {
                                    -webkit-transform: rotate(0deg);
                                    transform: rotate(0deg);
                                }

                                100% {
                                    -webkit-transform: rotate(359deg);
                                    transform: rotate(359deg);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @include for-phone-only {
        .home-header {
            height: 60vh;

            .mySwiper4 {
                .swiper-slide {
                    padding: 3rem 14px 0 14px;

                    .info {
                        width: 60%;

                        .btns {
                            button {
                                margin-right: 1rem;
                                min-width: 100px;
                                font-size: 0.8rem;
                            }
                        }
                    }

                    &.slide-one {
                        .img {
                            width: 145px;
                            height: 108px;

                            img.bitcoin {
                                width: 80px;
                                height: 70px;
                            }
                        }
                    }

                    &.slide-two {
                        .img {
                            .rocket {
                                width: 63px;
                                height: 110px;
                            }

                            .earth {
                                bottom: -48%;
                                left: -28%;
                                width: 182px;
                                height: 182px;
                            }
                        }
                    }
                }
            }
        }
    }

    @include for-tablet-potrait-up {
        .home-header {
            height: 100vh;
            width: 100%;

            .mySwiper4 {
                .swiper-slide {
                    padding: 3rem 40px 0 40px;


                    &.slide-one {
                        .img {
                            width: 250px;
                            height: 250px;

                            img.bitcoin {
                                z-index: 2;
                                position: relative;
                                width: 100px;
                                height: 140px;
                            }
                        }
                    }

                    &.slide-two {
                        .img {
                            .rocket {
                                width: 163px;
                                height: 270px;
                            }

                            .earth {
                                bottom: -17%;
                                left: 13%;
                                width: 245px;
                                height: 249px;
                            }
                        }
                    }
                }
            }
        }
    }

    @include for-tablet-landscape-up {}

    @include for-desktop-up {
        .home-header {
            height: 100vh;

            .mySwiper4 {
                .swiper-slide {
                    padding: 0 30px;

                    .info {
                        width: 50%;

                        h1 {
                            width: 80%;
                        }

                        .btns {
                            button {
                                margin-right: 2rem;
                            }
                        }
                    }

                    &.slide-one {
                        .img {
                            width: 599px;
                            height: 597px;

                            img.bitcoin {
                                width: 328px;
                                height: 430px;
                            }
                        }
                    }

                    &.slide-two {
                        .img {
                            .coin-sky {
                                .shower {
                                    background-size: 70%;
                                    height: 100vh;
                                }
                            }

                            .rocket {
                                width: 255px;
                                height: 453px;
                            }

                            .earth {
                                left: -10%;
                                bottom: -100%;
                                width: 786px;
                                height: 786px;
                            }
                        }
                    }
                }
            }
        }
    }

    @include for-big-desktop-up {}