    .contact {
        background-color: #000000;
        padding-top: 80px;

        h2 {
            font-size: 24px;
            line-height: 32px;
            font-weight: 600;
            padding-right: 80px;
            padding: 40px 16px;
        }


        .methods {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 10px;
            background-color: #181A20;
            padding: 40px 16px;
            border-radius: 50px 50px 0 0;

            .item {
                margin: 20px 0px;
                @include flexCC;
                flex-direction: column;

                .image {
                    position: relative;
                    width: 50px;
                    height: 50px;
                    color: $primaryColor;
                    margin-bottom: 15px;
                }


                button {
                    min-width: 150px;
                    background: linear-gradient(to right, $primaryColor 50%, transparent 50%);
                    background-size: 200% 100%;
                    background-position: right bottom;
                    transition: all .5s ease-out;

                    &:hover {
                        background-position: left bottom;
                        color: #181A20;
                    }
                }
            }
        }

        .form-container {
            background-color: #181A20;
            padding: 40px 16px;

            h3 {
                font-size: 25px;
                font-weight: 400;
            }

            form {

                .form-control {
                    width: 100%;
                    margin: 20px 0;
                    padding: 20px 0;
                }

                textarea {
                    width: 100%;
                    height: 30vh;
                    padding: 1rem;
                    font-size: 16px;
                    margin-top: 20px
                }

                .submitBtn {
                    @include flexCC;

                    button {
                        width: 200px;
                        margin-top: 20px;
                    }
                }

            }
        }
    }

    @include for-phone-only {}

    @include for-tablet-potrait-up {
        .contact {
            h2 {
                padding: 40px 24px;
                font-size: 32px;
                line-height: 40px;
            }

            .methods {
                flex-direction: row;
                padding: 40px 24px;

                .item {
                    margin: 0px 30px;
                    flex-direction: column;
                }
            }
        }
    }

    @include for-tablet-landscape-up {}

    @include for-desktop-up {
        .contact {

            h2 {
                font-size: 40px;
                padding: 40px 75px;
            }

            .methods {
                grid-template-columns: repeat(3, 1fr);
                padding: 40px 75px;

                .item {
                    margin: 0 50px;

                    .image {
                        margin-bottom: 30px;
                    }
                }
            }

            .form-container {
                padding: 40px 75px;

                form {
                    width: 50%;

                    .submitBtn {
                        justify-content: flex-start;

                    }

                }
            }
        }
    }

    @include for-big-desktop-up {}