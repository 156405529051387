.customers-activities {
    position: fixed;
    top: 50%;
    padding: 0rem 0 0.5rem 0;
    right: 0;
    background-color: $darkBg;
    box-shadow: 2px 2px 10px rgb(0, 0, 0);
    width: 70%;

    .progress {
        position: relative;
        height: 4px;
        width: 100%;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            background-color: $primaryColor;
            animation: progress linear 3s 1;


            @keyframes progress {
                from {
                    width: 0;
                }

                to {
                    width: 100%;
                }
            }
        }
    }

    p {
        font-size: clamp(0.9rem, 3vw, 1rem);
        opacity: 0.7;
        padding: 1rem 0.5rem;
    }
}

@include for-phone-only {}

@include for-tablet-potrait-up {
    .customers-activities {
        width: 50%;
    }
}

@include for-tablet-landscape-up {}

@include for-desktop-up {
    .customers-activities {
        width: auto;
    }
}

@include for-big-desktop-up {}