.wallet {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    padding: $mobileBodyPadding;

    .box {
        height: auto;
        position: relative;
        overflow: hidden;
        padding: 30px 10px;
        box-shadow: 0px 0px 1px rgb(0 0 0 / 5%), 0px 4px 32px rgb(58 53 47 / 5%);
        text-align: center;
        border: 1px solid $primaryColor;

        h3 {
            color: $primaryColor;
            margin-bottom: 10px;
            font-size: 10px;
            font-weight: 600;
        }

        p {
            font-size: 32px;
            font-weight: 600;

            &.green {
                color: #36B37E;
            }

            span {
                font-size: 25px;
                font-weight: 300;
            }

            .span2 {
                font-size: 20px;
                font-weight: 300;
            }
        }

        .card-bg {
            position: absolute;
            bottom: -30px;
            right: -30px;
            height: 100px;
            width: 100px;
            opacity: 0.05;

        }
    }

}

@include for-phone-only {
    .wallet {
        padding: $smallestBodyPadding;

        .box {
            h3 {
                font-size: 18px;
            }

            p {
                font-weight: 600;
                font-size: 35px;

                span {
                    font-size: 25px;
                    font-weight: 300;
                    color: rgb(27, 27, 27);

                    &.green {
                        color: #36B37E;
                    }
                }

                .span2 {
                    font-size: 15px;
                    font-weight: 300;
                }
            }
        }
    }
}

@include for-tablet-potrait-up {
    .wallet {
        grid-template-columns: repeat(3, 1fr);

        .box {
            h3 {
                font-size: 15px;
            }

            p {
                font-size: 40px;
                font-weight: 500;

                span {
                    font-size: 20px;
                    font-weight: 300;

                }
            }
        }

    }
}

@include for-tablet-landscape-up {}

@include for-desktop-up {
    .wallet {
        grid-template-columns: repeat(6, 1fr);
        padding: 50px 100px;

        .box {
            h3 {
                margin-bottom: 10px;
                font-size: 15px;
            }

            p {
                font-size: 35px;

                span {
                    font-size: 20px;
                    font-weight: 300;

                }
            }
        }

    }
}

@include for-big-desktop-up {}