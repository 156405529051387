.deposit {
  padding: 80px 5px;
  min-height: calc(100vh - 105px);

  .deposit-block {
    margin-top: 30px;
    border-radius: 4px;
    padding: 20px 10px;
    box-shadow: $boxShadow;
    background: $darkBg;

    .deposit-info {
      h3 {
        font-weight: 500;
        font-size: 30px;
        line-height: 19px;
        margin: 30px 0;
        color: $primaryColor;
        text-align: center;
      }

      .deposit-form {
        .form-control {
          position: relative;
          width: 100%;
          margin: 40px 0;
        }

        .submit-btn {
          @include flexCC;
          margin-top: 10px;

          button {
            width: 60%;
          }
        }
      }
    }

    .receipt {
      text-align: center;
      padding: 20px 0;

      h1 {
        font-size: 25px;
        font-weight: 500;
        color: $primaryColor;
        margin-bottom: 20px;
      }

      h3 {
        margin: 30px 0 10px 0;
      }

      .method {
        height: auto;
        margin: 15px 0;
        @include flexCC;
        flex-direction: column;

        .crypto {
          display: flex;
          align-items: center;
          min-width: 140px;

          img {
            width: 30px;
            margin-right: 10px;
          }

          h4 {
            font-size: 25px;
            font-weight: 300;
            cursor: pointer;
          }
        }

        .wallet-address {
          position: relative;
          padding: 0.5rem;
          background: $greyBackground;
          display: none;
          margin-top: 10px;
          font-size: 0.9rem;
          color: $darkBg;

          .copy-btn {
            font-size: 1.5rem;
            margin: 0 10px;
            cursor: pointer;
          }

          .confirmation {
            display: none;
            color: $primaryColor;
            font-weight: 700;

            &.show {
              display: block;
            }
          }

          &.show-address {
            @include flexCB;
          }
        }
      }

      button {
        margin-top: 20px;
      }
    }
  }
}

@include for-phone-only {
  .deposit {
    padding: $smallestBodyPadding;

    .deposit-block {
      .deposit-info {
        h3 {
          font-size: 25px;
        }
      }

      .receipt {
        text-align: center;
        padding: 20px 0;

        h1 {
          font-size: 25px;
        }
      }
    }
  }
}

@include for-tablet-potrait-up {
  .deposit {
    .deposit-block {
      margin: auto;
      margin-top: 30px;
      width: 60%;

      .deposit-info {
        h3 {
          font-size: 30px;
          line-height: 19px;
          text-align: center;
        }

        .deposit-form {
          .submit-btn {
            button {
              width: 80%;
            }
          }
        }
      }

      .receipt {
        .wallet-address {
          flex-direction: row;
          padding: 1rem;
        }
      }
    }
  }
}

@include for-tablet-landscape-up {
}

@include for-desktop-up {
  .deposit {
    padding: $desktopBodyPadding;
    padding-top: 120px;

    .deposit-block {
      border: 2px solid $primaryColor;
      width: 50%;

      .deposit-info {
        padding-bottom: 30px;

        .deposit-form {
          .submit-btn {
            button {
              width: 40%;
            }
          }
        }
      }
    }

    .receipt {
      text-align: center;
      padding: 20px 0;

      h1 {
        font-size: 25px;
        font-weight: 500;
        color: $primaryColor;
        margin-bottom: 20px;
      }

      h3 {
        margin: 30px 0 10px 0;
      }
    }
  }
}

@include for-big-desktop-up {
}
